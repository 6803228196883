<template>
  <div>
    <b-card
      no-body
      class="p-1"
    >
      <b-form-group
        :label="$t('Bulletin.typeBulletin')"
      >
        <v-select
          v-model="filterBulletinType"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :clearable="false"
          label="texto"
          :options="bulletinTypes"
          :reduce="option => option.id"
        />
      </b-form-group>
      <b-form-group>
        <b-form-input
          v-model="bulletinSearch"
          placeholder="Buscar boletin"
        />
      </b-form-group>
    </b-card>

    <b-card
      v-for="bulletin in bulletins"
      :key="bulletin.boletinId"
    >
      <b-card-header class="border-bottom mb-1">
        <div>
          <b-badge
            v-if="bulletin.nuevo"
            pill
            variant="light-success"
            class="mr-1"
          >
            <strong>Nuevo</strong>
          </b-badge>
          <strong> #{{ bulletin.boletinId }} - {{ bulletin.titulo }}</strong>
          <b-badge
            v-if="bulletin.fechaHoraCreacion"
            pill
            variant="light-primary"
            class="ml-1"
          >
            {{ bulletin.fechaHoraCreacion.substring(0, 10) }}
            <!-- {{ bulletin.boletinTipo.nombre }} -->
          </b-badge>
        </div>
        <b-badge
          pill
          variant="light-secondary"
        >
          Categoria: {{ bulletin.boletinTipo.nombre }}
        </b-badge>
      </b-card-header>
      <b-card-body>
        <div
          v-if="bulletin.readMore"
          v-html="bulletin.contenido"
        />
        <div
          v-if="!bulletin.readMore"
          v-html="`${bulletin.contenido.substring(0, 1000)}...`"
        />
        <b-button
          v-if="bulletin.contenido.length > 1000"
          size="sm"
          variant="outline-primary"
          @click="() => { bulletin.readMore = bulletin.readMore ? false : true}"
        >
          {{ bulletin.readMore === true ? 'Leer menos' : 'Leer mas' }}
        </b-button>
      </b-card-body>
      <span
        v-for="adjunto in bulletin.boletinAdjuntos"
        :key="adjunto.boletinAdjuntoId"
        class="cursor-pointer text-primary d-block"
        @click="downloadFile(adjunto)"
      >
        <feather-icon
          icon="PaperclipIcon"
        />
        <strong>{{ adjunto.archivo }}.{{ adjunto.extension }} </strong>
      </span>
    </b-card>
  </div>
</template>
<script>
import { ref, watch, set } from '@vue/composition-api'
import {
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import accountService from '@/services/account.service'
import fileService from '@/services/file.service'
import { saveAs } from 'file-saver'
import optionService from '@/services/option.service'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BFormGroup,
    BFormInput,
    BButton,

    vSelect,
  },
  setup() {
    /* Services */
    const {
      fetchBulletins,
      fetchBulletinsReadAll,
    } = accountService()
    const {
      fetchFile,
    } = fileService()
    const {
      fetchOptions,
    } = optionService()
    fetchBulletinsReadAll()
    /* Data */
    const bulletins = ref([])
    const filterBulletinType = ref(0)
    const bulletinSearch = ref('')

    const refetch = () => {
      fetchBulletins({
        boletinTipoId: filterBulletinType.value,
        searchQuery: bulletinSearch.value,
      }, data => {
        bulletins.value = data.boletines
        bulletins.value.forEach(item => {
          const bulletin = item
          set(bulletin, 'readMore', false)
        })
      })
    }
    const bulletinTypes = ref([{ id: 0, texto: '(Todos)' }])

    fetchOptions({ nombreDominio: 'TiposBoletin' }, data => {
      bulletinTypes.value = data
      bulletinTypes.value.push({ id: 0, texto: '(Todos)' })
    })

    const downloadFile = item => {
      fetchFile(item.guid, data => {
        const file = new File([data], `${item.archivo}.${item.extension}`)
        saveAs(file, `${item.archivo}.${item.extension}`)
      })
    }
    watch([filterBulletinType, bulletinSearch], () => {
      refetch()
    })

    refetch()

    return {
      bulletins,
      downloadFile,
      filterBulletinType,
      bulletinSearch,
      bulletinTypes,
    }
  },
}
</script>

<style>

</style>
